/*-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_*/
/*  SUMARY  */
/*-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_*/

#sumary {
    width: 100%;
    padding-bottom: 50px;
    background: var(--color111);
background: linear-gradient(180deg, var(--color2) 0%, var(--color111) 30%, var(--color111) 70%, var(--color2) 100%);

}

#sumary>section {
    padding: 50px;
    max-width: 1000px;
    margin: 0px auto;
}

#sumary>section>h1 {
    padding: 150px 0px 5px;
    font-size: var(--sumaryTitleFontSize);
    text-align: center;
}

#sumary>section>h2 {
    padding: 0px 0px 50px;
    text-align: center;
    color: var(--color9);
}

#sumary>section>p {
    text-align: center;
    font-weight: bold;
    padding-bottom: 100px;
}