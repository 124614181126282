/*-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_*/
/*-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_*/
:root {
  /*Colors*/
  --color111:#1A1A1A;
  --color1: #222222;
  --color2: #000000;

  /*--color3: #42C920;
  --color5: #8afa6e;*/
  --color33: rgb(0, 140, 255);
  --color3: #3c9983;
  --color5: #75c6b3;
  /*--color3: #b4a700;
  --color5: #ffed00;*/


  --color4: #ffffff;
  --color6: #c9c9c9;
  --color7: #434343;

  --color8: #b4a700;
  --color9: #ffed00;

  --color10: #42C920;
  --color11: #8afa6e;

  /*Fonts*/
  --sumaryTitleFontSize : 80px;
  --titleFontSize : 58px;
  --subTitleFontSize : 40px;
  --textFontSize:25px;
  --cardTextFontSize: 20px;
  --footerTextFontSize: 20px;
  --nameFontSize : 40px;
  /*Fonts Mobile*/
  --titleFontSizeMob : 60px;
  --subTitleFontSizeMob : 45px;
  --textFontSizeMob:35px;
  --cardTextFontSizeMob: 20px;

  /*Dimentions*/
  --cardHeight:500px;
}
body { 
  margin: 0px; 
  background-color: var(--color2);
  font-family: "Montserrat", sans-serif;
  color: var(--color4);
}
*{
  margin: 0px;
  padding: 0px;
}
p{
  font-size: var(--textFontSize);
  text-align:left;
}
h1{
  text-align: center;
  font-size: var(--titleFontSize);
}
h2{
  font-size: var(--subTitleFontSize);
}
h3{
  font-size: var(--textFontSizeMob);
}
span{
  font-size: var(--nameFontSize);
}




/*-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_*/
/*  Experience  */
/*-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_*/
#head-exp{
  padding-top: 20px;
  display: grid;
  grid-template-columns: 200px 1fr;
}
#head-exp>img{
  grid-row: 1/3;
  height: 60px;
}
#experience{
  color:var(--color1);
  background-color: var(--color3);
  padding: 100px 100px 150px;
}
#experience>section{
  width: 400px;
  margin: 0px auto;
  border: 2px solid var(--color6);
  box-shadow: 1rem 1rem  var(--color4);
  padding: 20px;
  background-color: var(--color2);
  color: var(--color4);
}
.work-info{
  min-height: 350px;
  max-width: 1000px;
  margin: 80px auto 0px;
  border-radius: 15px;
  border: 2px solid rgb(0, 0, 0);
  box-shadow: 2rem 2rem 0.5rem var(--color3);
  padding: 20px;
  background-color: var(--color4);
  
}
#experience>.work-info>img{
  height: 300px;
  padding: 30px;
  border-radius: 100px;
  float: left;
}
#experience>.work-info>p{
  padding: 80px 30px;
  font-style: italic;
  font-weight: bold;
  
}

/*-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_*/
/*  Projects  */
/*-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_*/
#Projects{
  background-color: var(--color1);
  padding: 100px 50px;
}
.cards{
  background-color: var(--color2);
  max-width: 1200px;
  margin: 0 auto;
  border: 5px solid var(--color5);
  border-radius: 18px;
}
.cards>h1{
  background-color: var(--color1);
  padding: 30px 0px;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  border-bottom: 5px solid var(--color5);
}
.cards>ul{
  width: 90%;
  margin: 0 auto;
  list-style-type: none;
  display: grid;
  padding: 50px 0px;
  gap: 50px;
  grid-template-columns: repeat(3,1fr);
}
.card{
  width: 100%;
  
}
.cardover{
  background-color: var(--color7);
  height: var(--cardHeight);
  display: grid;
  grid-template-columns: 50px 1fr 60px;
  grid-template-rows:60px 350px 30px 60px;
  padding: 20px;

  box-shadow: 1rem 1rem 0.2rem var(--color3);
  border: 2px solid var(--color3);
  border-radius: 10px;
  /*border: 1.5px solid var(--color4);*/
}
.cardover>h2{
  grid-column: 1/4;
  font-size: var(--subTitleFontSize);  
  text-align: center;
  color: var(--color4);
}
.cardover>button{
  /*box-shadow: 0.6rem 0.6rem 0rem var(--color3);*/
  
  border: 2px solid var(--color2);
  display: block;
  grid-column: 3;
  grid-row: 4;
  float: left;
  border-radius: 50%;
}
.cardover>button:hover{
  transform: rotate(360deg);
  transition: transform 1.5s;
  background-color: var(--color5);
}
.cardover>section{
  background-color: var(--color2);
  width: 100%;
  border: 2px solid var(--color10);
  color: var(--color3);
  grid-column: 1/4;
}
.cardover>section>p{
  font-size: var(--cardTextFontSize);
  width: 100%;
  margin: 10px auto;
  text-align: left;
  color: var(--color11);
}
.cardleaver{
  background-color: var(--color1);
  height: var(--cardHeight);
  display: grid;
  padding: 20px;

  box-shadow: 0.5rem 0.5rem 0.2rem var(--color5);
  border: 2px solid var(--color5);
  border-radius: 10px;
  /*border: 2px solid var(--color4);*/
}
.cardleaver>p{
  font-size: var(--cardTextFontSize);
  width: 100%;
  margin: 15px auto;
  text-align: center;
  color: var(--color4);
}
.cardleaver>h2{
  font-size: var(--subTitleFontSize);  
  text-align: center;
  color: var(--color4);
}
.cardleaver>img{
  
  border: 3px solid var(--color3);
  animation: projectImageShow 0.5s linear;
}
@keyframes projectImageShow{
  from{
    transform: translateY(-100%);
  }
  to{
    transform: translateY(0%);
  }
}

/*-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_*/
/*  MOBILE  */
/*-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_*/
@media only screen and (max-width: 981px) {
  .socialnets>ul{
    grid-template-columns: repeat(3,1fr);
  }
  .cards>ul{
    grid-template-columns: repeat(2,1fr);
  }
  #opensource>.linux-message>p{
    padding-top: 30px;
  }
  #headcontent>img{
    padding-bottom: 120px;
    
  }
  .profileimg {
    height: 100%;
  }
  p{
    font-size: var(--textFontSizeMob);
  }
  h1{
    font-size: var(--titleFontSizeMob);
  }
  span{
    font-size: var(--nameFontSize);
  }
} 