#platformsicons{
    width: 100%;
    padding: 50px 0px;
    margin: 0px;
}
#platformsicons>h1 {
    padding: 120px 0px 50px;
    font-size: var(--titleFontSize);
    text-align: center;
}
#platformsicons>section {
    max-width: 1000px;
    margin: 30px auto;
    background: rgb(255, 255, 255);
    background: radial-gradient(circle, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, var(--color3) 100%);
    border-radius: 30px;
    padding: 30px 30px 30px 14px;
}
.threeicons{
    display: grid;
    grid-template-columns: 1fr 15% 15% 15% 1fr;
    grid-column-gap: 30px;
    padding-bottom: 20px;
}
.threeicons>img,
.fouricons>img{
    border-radius: 15px;
    width: 100%;
    padding: 8px;
}

.threeicons>img:hover,
.fouricons>img:hover{
    background-color: var(--color1);
}

.fouricons{
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: 1fr 15% 15% 15% 15% 1fr;
    padding-bottom: 20px;
}

@media only screen and (max-width: 981px) {
    #platformsicons>section{
        max-width: 700px;
    }
}