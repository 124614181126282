/*-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_*/
/*  footer  */
/*-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_*/
footer {
    border-top: 5px solid var(--color3);
    padding: 12px 0px;
    position: relative;
    width: 100%;
    background-color: var(--color1);
    
  }
  footer>p{
    text-align: center;
    font-size: var(--footerTextFontSize);
  }